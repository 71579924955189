import { render, staticRenderFns } from "./UrlBanner.vue?vue&type=template&id=56621cbe&scoped=true&"
import script from "./UrlBanner.vue?vue&type=script&lang=ts&"
export * from "./UrlBanner.vue?vue&type=script&lang=ts&"
import style0 from "./UrlBanner.vue?vue&type=style&index=0&id=56621cbe&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56621cbe",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBanner } from 'vuetify/lib/components/VBanner';
installComponents(component, {VBanner})
