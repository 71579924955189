


















import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'

import Modal from '@/components/modals/Modal.vue'

@Component({
    components: {
        Modal,
    },
})
export default class XpubModal extends Vue {
    @Prop({ default: '' }) xpub!: string

    open(): void {
        let modal = this.$refs.modal as Modal
        modal.open()
    }
}
