













import { Vue, Component, Prop } from 'vue-property-decorator'
import { WalletType } from '@/js/wallets/types'

import { LedgerWallet } from '@/js/wallets/LedgerWallet'
import { ava } from '@/AVA'
import { getPreferredHRP } from '@lamina1/lamina1-js/dist/utils'
import { AVA_ACCOUNT_PATH } from '@/js/wallets/MnemonicWallet'

@Component
export default class HdEmptyAddressRow extends Vue {
    @Prop() index!: number
    @Prop() path!: number
    @Prop() address!: string

    get wallet() {
        return this.$store.state.activeWallet as WalletType
    }

    get walletType() {
        return this.wallet.type
    }

    async verifyLedgerAddress() {
        const wallet = this.wallet as LedgerWallet

        let networkId = ava.getNetworkID()
        let hrp = getPreferredHRP(networkId)

        wallet.app.getWalletAddress(`${AVA_ACCOUNT_PATH}/${this.path}/${this.index}`, hrp)
    }
}
